import * as React from "react";
import Layout from "../components/layout";
import Sidebar from "../components/sidebar";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import SEO from "../components/seo";

const SingleCarattersistiche = () => {
  return (
    <Layout>
      <SEO
        title="Cosa serve per aprire un call center"
        description="Ecco cosa serve per aprie un call center outbound. Software completo per gestire liste e chiamate automatiche"
      />
      <section className="px-6 py-12">
        <div className="container mx-auto">
          <div className="flex flex-wrap gap-12">
            <aside className="w-full lg:w-3/12">
              <Sidebar />
            </aside>
            <div className="article w-full lg:w-8/12 mb-12">
              <span className="tag">Caratteristiche</span>
              <h1 className="text-persian-green-500 text-5xl">
                Aprire un Call Center?
              </h1>
              <h2 className="text-gray-800 text-5xl mb-6">
                Ecco cosa devi sapere!
              </h2>
              <span className="block text-gray-400 text-3xl mb-6">
                Hai deciso di aprire un call center? Ottima idea! Ma prima di
                iniziare, ci sono alcuni passaggi fondamentali da seguire.
              </span>
              <div className="entry prose prose-persian-green max-w-none">
                {/* <p className="text-lg">Per aprire un call center occorre prima di tutto scegliere il programma o software con le funzionalità complete ed utili all’attività da svolgere. Scegliere le caratteristiche giuste, il numero di postazioni, l’usabilità dell’interfaccia sono solo alcune degli aspetti rilevanti per aprire un call center.</p> */}
                <h3>I primi passi</h3>
                <ul>
                  <li>
                    <b>Registrati al ROC:</b> Oltre alla solita partita IVA,
                    dovrai iscrivere il tuo call center al Registro degli
                    Operatori della Comunicazione. È un po' come una licenza per
                    poter fare chiamate commerciali.
                  </li>
                  <li>
                    <b>Crea le tue liste:</b> Avrai bisogno di una lista di
                    persone interessate a ricevere le tue chiamate. Assicurati
                    di avere il loro consenso e di rispettare la loro privacy.
                  </li>
                  <li>
                    <b>Pulisci le liste:</b> Prima di chiamare qualcuno,
                    controlla che il suo numero non sia presente nel Registro
                    Pubblico delle Opposizioni. È come un "blocca chiamate"
                    nazionale.
                  </li>
                </ul>
                <h3>Un software indispensabile</h3>
                <p className="text-lg">
                  Per gestire tutto questo, ti servirà un software specifico per
                  call center. Questo strumento ti aiuterà a:
                </p>
                <ul>
                  <li>
                    <b>Organizzare le tue chiamate:</b> Assegnare le chiamate
                    agli operatori, tenere traccia dei risultati e pianificare
                    le tue campagne.
                  </li>
                  <li>
                    <b>Gestire le liste:</b> Importare, pulire e organizzare le
                    tue liste di contatti in modo semplice ed efficace.
                  </li>
                  <li>
                    <b>Migliorare le tue performance:</b> Analizzare i risultati
                    delle tue chiamate e capire cosa funziona e cosa no.
                  </li>
                </ul>
                <h3>La scelta delle linee telefoniche</h3>
                <p className="text-lg">
                  Le linee telefoniche sono fondamentali per un call center.
                  Scegli una soluzione di qualità che ti permetta di:
                </p>
                <ul>
                  <li>
                    <b>Risparmiare:</b> Opta per tariffe competitive e senza
                    sorprese.
                  </li>
                  <li>
                    <b>Essere efficiente:</b> Assicurati di avere una buona
                    qualità audio e un sistema di inoltro delle chiamate veloce
                    e affidabile.
                  </li>
                </ul>
                <h3>Perché scegliere Thelgo?</h3>
                <p className="text-lg">
                  Thelgo è la soluzione completa per il tuo call center.
                  Offriamo tutto ciò di cui hai bisogno: software, linee
                  telefoniche e assistenza. Con Thelgo, potrai:
                </p>
                <ul>
                  <li>
                    <b>Iniziare subito:</b> Semplifichiamo al massimo le
                    procedure, così potrai concentrarti sulla tua attività.
                  </li>
                  <li>
                    <b>Essere sicuro:</b> Garantiamo standard di qualità elevati
                    e rispettiamo tutte le normative vigenti.
                  </li>
                  <li>
                    <b>Avere successo:</b> Ti offriamo gli strumenti e il
                    supporto necessari per raggiungere i tuoi obiettivi.
                  </li>
                </ul>
                <h3>In sintesi</h3>
                <p className="text-lg">
                  Aprire un call center è più semplice di quanto pensi, ma
                  richiede una buona organizzazione e gli strumenti giusti. Con
                  Thelgo, avrai tutto ciò che ti serve per partire con il piede
                  giusto.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto">
          <div className="text-center mb-6">
            <span className="tag">Funzionalità</span>
            <h3 className="text-3xl text-gray-800">Cosa include Thelgo</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
            <div className="elenco-funzioni">
              <div>
                <StaticImage
                  src="../images/func-sicurezza.png"
                  alt="Sicurezza Thelgo"
                  placeholder="blurred"
                  className="mb-3"
                  layout="fixed"
                  height={100}
                />
              </div>
              <h3>Tecnologia e Sicurezza</h3>
              <ul>
                <li>Tecnologia Web HTML5 CSS3</li>
                <li>
                  Interfaccia Responsive adatta a qualsiasi risoluzione video
                </li>
                <li>Multilingua (It – En –Fr –De -Ro – Es )</li>
                <li>Utilizzo Softphone o telefoni IP (Versione FLAT)</li>
                <li>
                  Api Webservices per importare Liste nominativi (solo versione
                  dedicata)
                </li>
                <li>App mobile per Agenti</li>
                <li>Accesso restrittivo tramite settaggio range IP</li>
                <li>Accesso protetto Https</li>
              </ul>
            </div>

            <div className="elenco-funzioni">
              <div>
                <StaticImage
                  src="../images/func-gsm.png"
                  alt="Centralino Thelgo"
                  placeholder="blurred"
                  className="mb-3"
                  layout="fixed"
                  height={100}
                />
              </div>
              <h3>Centralino VOIP</h3>
              <ul>
                <li>Centralino software PBX Cloud (Versione FLAT)</li>
                <li>Caller Id personalizzabile su Campagna</li>
                <li>Power Dialer (Versione FLAT)</li>
                <li>Progressive Dialer (Versione FLAT)</li>
                <li>Predictive Dialer (versione FLAT)</li>
                <li>Chiamate inboud con IVR</li>
                <li>
                  Intrusione chiamate per formazione personali(Versione FLAT)
                </li>
                <li>
                  Registrazione delle chiamate impostabile per campagna
                  (Versione FLAT)
                </li>
              </ul>
            </div>

            <div className="elenco-funzioni">
              <div>
                <StaticImage
                  src="../images/func-report.png"
                  alt="Report Thelgo"
                  placeholder="blurred"
                  className="mb-3"
                  layout="fixed"
                  height={100}
                />
              </div>
              <h3>Report e analisi</h3>
              <ul>
                <li>
                  Consultazione ed Export Statistiche Campagne – Operatori –
                  Appuntamenti – Eventi – Acquisti – Email – Chiamate
                </li>
                <li>
                  Dashboard con grafici Attività Operatori – Chiamate – Campagne
                </li>
                <li>Monitoraggio degli operatori inattivi</li>
                <li>
                  Dettagli su tempi cdi conversazione, attesa chiamate, esito
                  schede, pause lavoro…
                </li>
              </ul>
            </div>

            <div className="elenco-funzioni">
              <div>
                <StaticImage
                  src="../images/func-func.png"
                  alt="Funzioni Thelgo"
                  placeholder="blurred"
                  className="mb-3"
                  layout="fixed"
                  height={100}
                />
              </div>
              <h3>Funzionalità</h3>
              <ul>
                <li>
                  Importazione liste nominativi da File Excel – Cvs (con campi
                  dinamici)
                </li>
                <li>Gestione Script, sondaggi e profilazione</li>
                <li>Gestione quote esiti su campi dinamici nominativi</li>
                <li>
                  Campagne dinamiche con incrocio esiti / sondaggi /
                  profilazione di precedenti campagne
                </li>
                <li>Gestione Richiami Pubblici e Privati</li>
                <li>Gestione limite massimo richiamare Privati</li>
                <li>Gestione Registro Pubblico delle Opposizioni</li>
                <li>
                  Gestione limite chiamate mute “provvedimento 83 del 20
                  febbraio 2014”
                </li>
                <li>Gestione e invio Materiale Promozionale via Email</li>
                <li>
                  Profili di accesso differenziati per Supervisore, Operatore,
                  Agente, Committente
                </li>
                <li>
                  Inserimento in black list massivo di nominativi da file xls,
                  csv
                </li>
              </ul>
            </div>

            <div className="elenco-funzioni">
              <ul className="lg:mt-36">
                <li>
                  Distribuzione chiamate in base concentrazioni appuntamenti
                  Agenti
                </li>
                <li>
                  Impostazione Avanzate Agenti: Orario di lavoro settimanale –
                  limite appuntamenti – associazione a campi nominativi (area
                  geografica, categoria merceologica, età ….)
                </li>
                <li>
                  Distribuzione degli appuntamenti agli agenti programmata
                </li>
                <li>Gestione Esiti Appuntamenti</li>
                <li>Riassegnazione richiamare appuntamenti ad operatori</li>
                <li>
                  Funzione Blocca (campi dinamici operatori come Località,
                  provincia, sesso ect..)
                </li>
                <li>Gestione Sms/Email remind appuntamenti</li>
                <li>Gestione Eventi (meeting, spettacoli etc..)</li>
                <li>Geolocalizzazione nominativi</li>
                <li>Geolocalizzazione appuntamenti per agenti</li>
              </ul>
            </div>

            <div className="elenco-funzioni">
              <div>
                <StaticImage
                  src="../images/func-sos.png"
                  alt="Funzioni Thelgo"
                  placeholder="blurred"
                  className="mb-3"
                  layout="fixed"
                  height={100}
                />
              </div>
              <h3>Supporto</h3>
              <ul>
                <li>Supporto telematico (a mezzo email/ticket)</li>
                <li>Supporto telefonico incluso</li>
              </ul>
            </div>
          </div>

          <div className="text-center mt-12">
            <span className="block text-persian-green-500 text-4xl">
              Vuoi aprire un Call Center?
            </span>
            <Link
              to="/contatti"
              className="button-lg inline-flex mt-4 bg-persian-green-500 hover:bg-persian-green-600 text-white transition-colors"
            >
              Contattaci subito
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SingleCarattersistiche;
